
// COMPONENTS
//import TableWidget9 from "@/components/widgets/tables/Widget9.vue";

// APP
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
	name: "UsersList",
/*	components: {
		TableWidget9,
	},
*/
	props: {
		widgetClasses: String,
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const colors = ["primary", "secondary", "success", "info", "warning", "danger", "dark"];
		const headers = [
			{ name: "User", class: "min-w-150px" },
			{ name: "Role", class: "min-w-110px" },
			{ name: "Division", class: "min-w-110px" },
			{ name: "Verified", class: "min-w-90px" },
			{ name: "Active", class: "min-w-90px" },
			{ name: "Actions", class: "min-w-100px text-center" },
		];
		const resendButton = ref<HTMLElement | null>(null);

		// REACTIVE
		let userLists = ref([]);
		let loading = ref(true);
		const checked = ref(false);

		// METHODS
		const randomColor = (len: number) => {
			return colors[Math.floor(Math.random() * len)];
		};

		const setActiveState = async ( id, email) => {
			const payload = {
				data: {
					user_id: id,
					email: email	
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("admin/users/set-active", payload)
				.then(async ({ data }) => {
					let users = await loadUserList();

					if (users != undefined && Object.prototype.hasOwnProperty.call(users, 'code') && users.code == 200 && users.data.length > 0) {
						userLists.value = users.data;
					}

					return data.active;
				})
				.catch(({ response }) => {
					console.log(response);
				});
		};

		const sendEmailVerification = async ( id, email) => {

			if (resendButton.value) {
				// Activate indicator
				resendButton.value.setAttribute("data-kt-indicator", "on");
			}

			const payload = {
				data: {
					user_id: id,
					email: email	
				}
			};

			ApiService.setHeader();
			const resp = await ApiService.post("admin/user/resend-email-verification", payload)
				.then(async ({ data }) => {
					
					Swal.fire({
						text: "Email verification sent",
						icon: "success",
						buttonsStyling: false,
						confirmButtonText: "Ok",
						customClass: {
							confirmButton: "btn btn-primary",
						},
					});

				})
				.catch(({ response }) => {
					console.log(response);
				});

				resendButton.value?.removeAttribute("data-kt-indicator");

		};


		const loadUserList = async () => {
			let users = await store.dispatch(Actions.USERS_LIST);
			return users;
		};

		onMounted(async () => {
			setCurrentPageTitle("Users");
			let users = await loadUserList(); //store.dispatch(Actions.USERS_LIST);

			if (users != undefined && Object.prototype.hasOwnProperty.call(users, 'code') && users.code == 200 && users.data.length > 0) {
				userLists.value = users.data;
			}

			//console.log(users);

			loading.value = false;
		});

		return {
			checked,
			userLists,
			loading,
			randomColor,
			headers,
			setActiveState,
			sendEmailVerification,
			resendButton,
		};
	},

}
